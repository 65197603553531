<template>
  <v-container fluid class="pa-0 about-us2">
    <banner :title="title"></banner>
    <v-row class="pa-lg-16 pa-10 ">
      <v-col col="12" xs="12" class="px-lg-16">
        <p class="josefin-light black-grin p text-lg-justify text-center">
          {{ $t("about.aboutDescription") }}
        </p>
      </v-col>
    </v-row>

    <v-row class="back-about">
      <v-row class="mt-0">
        <v-col cols="12" xs="12" lg="4" class="mision">
          <v-row class="pa-10" justify="center">
            <v-col col="12" xs="12" lg="9">
              <h1 class="copperplate-light white-black h1 text-center my-3">
                {{ $t("about.mission") }}
              </h1>
              <p class="josefin-light white-black p_aboutUs text-center shadow">
                {{ $t("about.missionDescription") }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" lg="4" class="vision">
          <v-row class="pa-10" justify="center">
            <v-col col="12" xs="12" lg="9">
              <h1 class="copperplate-light white-black h1 text-center my-3">
                {{ $t("about.view") }}
              </h1>
              <p class="josefin-light white-black p text-center shadow">
                {{ $t("about.viewDescription") }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" lg="4" class="valores">
          <v-row class="pa-10" justify="center">
            <v-col col="12" xs="12" lg="9">
              <h1 class="copperplate-light white-black h1 text-center my-3">
                {{ $t("about.values") }}
              </h1>
              <ul class="josefin-light white-black p text-center shadow pl-0">
              <li>{{ $t("about.value1") }}</li>
              <li>{{ $t("about.value2") }}</li>
              <li>{{ $t("about.value3") }}</li>
              <li>{{ $t("about.value4") }}</li>
              <li>{{ $t("about.value5") }}</li>
              <li>{{ $t("about.value6") }}</li>
              <li>{{ $t("about.value7") }}</li>
              <li>{{ $t("about.value8") }}</li>
              <li>{{ $t("about.value9") }}</li>
              <li>{{ $t("about.value10") }}</li>
              <li>{{ $t("about.value11") }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="pa-lg-16 pa-10">
        <v-col cols="12" xs="12" lg="8" class="px-lg-16 py-lg-16 py-10">
          <div>
            <h1 class="copperplate-medium white-black h1 my-3 text-center text-lg-left">
              {{$t('about.theGreatestSenseOfQuality')}}
            </h1>
            <p class="josefin-light white-black p text-lg-justify text-center">
              {{$t('about.theGreatestSenseOfQualityDescription')}}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-row>
    <v-row class="house d-lg-none" justify="end"> </v-row>
    <v-row class="d-lg-none align-center d-flex">
      <v-col cols="12" xs="12" lg="5" class="yellow-frame align-center d-flex py-16">
        <div>
          <h1 class="copperplate-bold font-weight-bold h1 my-5 text-center">
            {{$t('about.whoIsADonMiguel')}}
          </h1>
          <p class="josefin-regular black-grin p text-lg-justify text-center px-10">
             {{$t('about.whoIsADonMiguelDescription')}}
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="house px-16 d-none d-lg-flex" justify="start">
      <v-col cols="12" xs="12" lg="5" class="mr-16 pt-0">
        <div class="yellow-frame py-16">
          <h1 class="copperplate-bold font-weight-bold h1 my-3 text-center px-16 ">
            {{$t('about.whoIsADonMiguel')}}
          </h1>
          <p class="josefin-regular black-grin p text-lg-justify text-center px-16">
           {{$t('about.whoIsADonMiguelDescription')}}
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="pt-10 px-lg-16 d-flex flex-wrap-reverse">
      <v-col cols="12" lg="6" class="pa-lg-16 ">
        <h1 class="copperplate-medium h3 gold my-3 text-center text-lg-left">
          {{$t('about.ourShield')}}
        </h1>
        <p class="josefin-light black-grin p text-lg-justify text-center px-10 px-lg-0">
          {{$t('about.ourShieldDescription')}}
        </p>
      </v-col>
      <v-col cols="12" lg="6" class="pa-lg-16">
        <v-row>
          <v-img contain src="@/assets/escudo.svg"  class="shield"> </v-img>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="pb-10 ">
      <v-col cols="12" lg="4" class="pt-1">
        <div>
          <v-row class="">
            <v-img src="@/assets/palo.png" contain height="284" class="img-palo"> </v-img>
          </v-row>
          <v-row class="pl-16 mt-n16">
            <v-img contain class=" ml-10 " src="@/assets/logo_palo.png" height="150"> </v-img>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" lg="6" class="py-14 px-lg-16">
        <h1 class="copperplate-medium h3 gold my-3 text-center text-lg-left">
         {{$t('about.innovation')}}
        </h1>
        <p class="josefin-light black-grin p text-lg-justify text-center px-10 px-lg-0"
        v-html="$t('about.innovationDescription')">
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import banner from "@/components/home/banner.vue";

export default {
  components: {
    banner
  },
  data() {
    return {
      title: "about.aboutTitle"
    };
  }
};
</script>
